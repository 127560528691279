'use client'

import { forwardRef, InputHTMLAttributes, useState, ReactElement } from 'react'

import { twMerge } from 'tailwind-merge'
import { EyeFilled, EyeOffFilled } from '../../icons'
import clsx from 'clsx'

export type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  helper?: string | ReactElement
  label?: string
  error?: string | ReactElement
}

export const TextFieldPlatform = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextFieldImpl(
    { label, error, helper, required, className, ...props },
    ref
  ) {
    const [showPassword, setShowPassword] = useState(false)
    const isInputPassword = props.type === 'password'

    return (
      <div>
        <div className="relative">
          <input
            id={props.name}
            aria-describedby="floating_helper_text"
            className={twMerge(
              'peer block w-full appearance-none rounded-b-lg rounded-t-lg border-0 border-b-2 border-platform-blue-100 bg-platform-blue-100 px-2.5 pb-1.5 pt-5 text-base font-normal text-platform-black-950 focus:border-blue-600 focus:outline-none focus:ring-0 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:focus:border-blue-500',
              error &&
                'border-red-600 focus:border-red-600 dark:border-red-500 dark:focus:border-red-500',
              // className to delete arrow for input field of number type
              '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
            )}
            {...props}
            ref={ref}
            placeholder=" "
            type={
              isInputPassword
                ? showPassword
                  ? 'text'
                  : 'password'
                : props.type
            }
          />

          <label
            htmlFor={props.name}
            className={twMerge(
              'absolute start-2.5 top-4 z-10 origin-[0] -translate-y-3 scale-75 transform text-sm font-light tracking-wider text-platform-black-950 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-3 peer-focus:scale-75 peer-focus:text-blue-600 dark:text-gray-400 peer-focus:dark:text-blue-500 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4'
              // error &&
              //   'peer-focus:text-red-600 peer-focus:dark:text-red-500 text-red-500 dark:text-red-400'
            )}>
            {label}
          </label>

          {isInputPassword && (
            <div
              className="absolute right-3 top-4 cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}>
              <EyeOffFilled
                className={clsx(
                  'h-[18px] w-[18px]',
                  showPassword ? 'hidden' : 'visible'
                )}
              />
              <EyeFilled
                className={clsx(
                  'h-[18px] w-[18px]',
                  showPassword ? 'visible' : 'hidden'
                )}
              />
            </div>
          )}
        </div>
        <p
          className={twMerge(
            'my-1 text-xs text-red-600 dark:text-red-400',
            !error && 'invisible'
          )}>
          {error || '-'}
        </p>
        {!error && helper && (
          <p
            id="floating_helper_text"
            className="mt-2 text-xs text-platform-black-500 dark:text-gray-400">
            {helper}
          </p>
        )}
      </div>
    )
  }
)
